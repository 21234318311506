import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, Collapse, List, IconButton } from '@mui/material';

// project import
import { activeItem, openDrawer } from 'store/reducers/menu';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const itemRef = useRef();
    const dispatch = useDispatch();
    const params = useParams();
    const menu = useSelector((state) => state.menu);
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const { drawerOpen, openItem } = menu;

    const [isOpenExpand, setIsOpenExpand] = useState(false);

    const handleClickExpand = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpenExpand((prev) => !prev);
    };

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = (url, itemTarget) => ({
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={url} target={itemTarget} />)
    });

    // if (item?.external) {
    //     listItemProps = { component: 'a', href: item.url, target: itemTarget };
    // }

    const itemHandler = (id) => {
        if (decodeURI(document.location.pathname).includes(id)) dispatch(activeItem({ openItem: [id] }));
        if (matchDownMD) {
            dispatch(openDrawer({ drawerOpen: false }));
        }
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

    const isSelected = (itemId) => openItem.at(0) === itemId;

    // Determine if the current item has child items
    const hasChildItems = !!item.items && item.items.length > 0;

    // active menu item on page load
    useEffect(() => {
        const pathName = decodeURI(document.location.pathname);
        if (item?.items?.some((el) => el.url === pathName)) {
            dispatch(activeItem({ openItem: [item?.items?.find((el) => el.url === pathName).id] }));
            if (!isOpenExpand) setIsOpenExpand(true);
        } else if (pathName == item.url) {
            dispatch(activeItem({ openItem: [item.id] }));
        } else if (pathName == '/') {
            dispatch(activeItem({ openItem: ['dashboard'] }));
        }
    }, [params, item?.items]);

    useEffect(() => {
        if (isSelected(item.id)) {
            itemRef.current.scrollIntoView();
        }
    }, []);

    const textColor = 'text.primary';
    const iconSelectedColor = item.color === 'third' ? 'text.primary' : 'primary.main';

    return (
        <>
            <ListItemButton
                {...listItemProps(item.url, itemTarget)}
                disabled={item.disabled}
                // onClick={() => itemHandler(item.id)}
                selected={isSelected(item.id)}
                ref={itemRef}
                sx={{
                    zIndex: 1201,
                    pl: drawerOpen ? `${level * 28}px` : 1.5,
                    py: !drawerOpen && level === 1 ? 1.25 : 1,
                    pr: '2px',
                    ...(drawerOpen && {
                        '&:hover': {
                            bgcolor: "item.color === 'third' ? 'third.lighter' : 'primary.lighter'"
                        },
                        '&.Mui-selected': {
                            bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter',
                            pr: '0',
                            borderRight: `2px solid ${item.color === 'third' ? theme.palette.third.dark : theme.palette.primary.main}`,
                            color: iconSelectedColor,
                            '&:hover': {
                                color: iconSelectedColor,
                                bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter'
                            }
                        }
                    }),
                    ...(!drawerOpen && {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        '&.Mui-selected': {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            bgcolor: 'transparent'
                        }
                    })
                }}
            >
                {itemIcon && (
                    <ListItemIcon
                        sx={{
                            minWidth: 28,
                            color: isSelected(item.id) ? iconSelectedColor : textColor,
                            ...(!drawerOpen && {
                                borderRadius: 1.5,
                                width: 36,
                                height: 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    bgcolor: item.color === 'third' ? 'third.lighter' : 'secondary.lighter'
                                }
                            }),
                            ...(!drawerOpen &&
                                isSelected(item.id) && {
                                    bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter',
                                    '&:hover': {
                                        bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter'
                                    }
                                })
                        }}
                    >
                        {itemIcon}
                    </ListItemIcon>
                )}
                {(drawerOpen || (!drawerOpen && level !== 1)) && (
                    <ListItemText
                        primary={
                            <Typography variant="h6" sx={{ color: isSelected(item.id) ? iconSelectedColor : textColor }}>
                                {item.title}
                            </Typography>
                        }
                    />
                )}
                {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                    <Chip
                        color={item.chip.color}
                        variant={item.chip.variant}
                        size={item.chip.size}
                        label={item.chip.label}
                        avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                    />
                )}

                {hasChildItems && (
                    <IconButton
                        onClick={handleClickExpand}
                        sx={{
                            minWidth: 28,
                            mr: 1,
                            borderRadius: '100%',
                            color: isSelected(item.id) ? iconSelectedColor : textColor,
                            ...(!drawerOpen && {
                                borderRadius: 1.5,
                                width: 36,
                                height: 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    bgcolor: item.color === 'third' ? 'third.lighter' : 'secondary.lighter'
                                }
                            }),
                            ...(!drawerOpen &&
                                isSelected(item.id) && {
                                    bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter',
                                    '&:hover': {
                                        bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter'
                                    }
                                })
                        }}
                    >
                        <ListItemIcon sx={{ justifyContent: 'center' }}>{isOpenExpand ? <UpOutlined /> : <DownOutlined />}</ListItemIcon>
                    </IconButton>
                )}
            </ListItemButton>
            {hasChildItems && (
                <Collapse in={isOpenExpand} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {item?.items.map((child, key) => (
                            <ListItemButton
                                key={key}
                                item={child}
                                {...listItemProps(child.url, itemTarget)}
                                disabled={item.disabled}
                                onClick={() => itemHandler(child.id)}
                                selected={isSelected(child.id)}
                                ref={itemRef}
                                sx={{
                                    zIndex: 1201,
                                    pl: drawerOpen ? `${level * 56}px` : 1.5,
                                    py: !drawerOpen && level === 1 ? 1.25 : 1,
                                    ...(drawerOpen && {
                                        '&:hover': {
                                            bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter'
                                        },
                                        '&.Mui-selected': {
                                            bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter',
                                            borderRight: `2px solid ${
                                                item.color === 'third' ? theme.palette.third.dark : theme.palette.primary.main
                                            }`,
                                            color: iconSelectedColor,
                                            '&:hover': {
                                                color: iconSelectedColor,
                                                bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter'
                                            }
                                        }
                                    }),
                                    ...(!drawerOpen && {
                                        '&:hover': {
                                            bgcolor: 'transparent'
                                        },
                                        '&.Mui-selected': {
                                            '&:hover': {
                                                bgcolor: 'transparent'
                                            },
                                            bgcolor: 'transparent'
                                        }
                                    })
                                }}
                            >
                                {child.icon && (
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 28,
                                            color: isSelected(child.id) ? iconSelectedColor : textColor,
                                            ...(!drawerOpen && {
                                                borderRadius: 1.5,
                                                width: 36,
                                                height: 36,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                '&:hover': {
                                                    bgcolor: item.color === 'third' ? 'third.lighter' : 'secondary.lighter'
                                                }
                                            }),
                                            ...(!drawerOpen &&
                                                isSelected(child.id) && {
                                                    bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter',
                                                    '&:hover': {
                                                        bgcolor: item.color === 'third' ? 'third.lighter' : 'primary.lighter'
                                                    }
                                                })
                                        }}
                                    >
                                        {<child.icon />}
                                    </ListItemIcon>
                                )}
                                {(drawerOpen || (!drawerOpen && level !== 1)) && (
                                    <ListItemText
                                        primary={
                                            <Typography variant="h6" sx={{ color: isSelected(child.id) ? iconSelectedColor : textColor }}>
                                                {child.title}
                                            </Typography>
                                        }
                                    />
                                )}
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
