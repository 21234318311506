import React, { useEffect } from 'react';
import { useGridApiContext } from '@mui/x-data-grid-premium';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FilterListIcon from '@mui/icons-material/FilterList';
import ErrorBoundary from 'components/ErrorBoundary/index';
import { ClickAwayListener, Paper, Popper } from '@mui/material';

const HeaderFilterWrapper = ({ item, colDef }) => {
    const { filterOperators } = colDef;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const OPERATORS_RESET_VALUE = ['is', 'not'];
    const STRING_OPERATORS = ['contains', 'equals', 'startsWith', 'endsWith'];

    const handleCloseMenu = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const apiRef = useGridApiContext();

    const selectedOperator = filterOperators?.find((op) => item?.operator === op.value) || filterOperators?.at(0);

    const handleFilterOperatorChange = (operator) => {
        let updatedItem = { ...item };

        const shouldResetValueForOperator = OPERATORS_RESET_VALUE.includes(operator) && !OPERATORS_RESET_VALUE.includes(item.operator);
        const shouldResetValueForStringOperators =
            (Array.isArray(item.value) || typeof item.value === 'object') && STRING_OPERATORS.includes(operator);

        if (shouldResetValueForOperator || shouldResetValueForStringOperators) {
            updatedItem.value = '';
        }

        apiRef.current.upsertFilterItem({
            ...updatedItem,
            operator
        });
    };

    const handleChange = (item) => {
        if (!item.value) {
            apiRef.current.deleteFilterItem(item);
        } else {
            apiRef.current.upsertFilterItem({
                ...item
            });
        }
    };

    useEffect(() => {
        if (!filterOperators.find((el) => el.value === item.operator)) {
            handleFilterOperatorChange(filterOperators.at(0).value);
        }
    }, [item, filterOperators]);

    const filterComponentProps = {
        item,
        selectedOperator: selectedOperator,
        applyValue: handleChange,
        ...selectedOperator?.InputComponentProps,
        chipBoxProps: { sx: { px: '4px', py: '2px', pb: '4px' } }
    };

    const FilterComponent = selectedOperator?.InputComponent;

    return (
        <Stack direction="row" alignItems="center" sx={{ width: '100%', height: '100%' }}>
            <Stack sx={{ maxWidth: 'calc(100% - 30px)', height: '100%', width: '100%', pr: '5px' }} justifyContent="center">
                <ErrorBoundary fallback={'Something went wrong...'}>
                    <FilterComponent {...filterComponentProps} />
                </ErrorBoundary>
            </Stack>
            <Button
                ref={anchorRef}
                onClick={handleToggle}
                sx={{
                    flexShrink: 0,
                    marginLeft: 'auto',
                    color: 'customColorTableStringFilter.colorButton',
                    minWidth: '22px',
                    p: '2px',
                    fontSize: '1.25rem',
                    margin: 'auto 0 9px 5px'
                }}
            >
                <FilterListIcon sx={{ fontSize: '1.25rem' }} />
            </Button>
            <Popper anchorEl={anchorRef.current} open={open} placement="bottom-start" sx={{ zIndex: 1300 }}>
                <Paper
                    sx={{
                        backgroundColor: 'customColorTableFilter.colorFilterBG',
                        py: 1
                    }}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        <div>
                            {filterOperators?.map((operator) => (
                                <MenuItem
                                    key={operator.label}
                                    sx={{
                                        backgroundColor:
                                            item?.operator?.toLowerCase() === operator?.value?.toLowerCase()
                                                ? 'customColorTableStringFilter.colorMenuItemRGBA'
                                                : ''
                                    }}
                                    onClick={() => {
                                        handleFilterOperatorChange(operator.value);
                                        handleCloseMenu();
                                    }}
                                >
                                    {operator.label}
                                </MenuItem>
                            ))}
                        </div>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </Stack>
    );
};

export default HeaderFilterWrapper;
