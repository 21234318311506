// material-ui
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';

// project import
import {drawerWidth, drawerWidthMd} from 'config';

// ==============================|| HEADER - APP BAR STYLED ||============================== //

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
        [theme.breakpoints.down('lg')]: {
            ...(open && {
                marginLeft: drawerWidthMd,
                width: open ? `calc(100% - ${drawerWidthMd}px)` : '100%',
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }),
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: drawerWidth,
            width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
        }
    })
);

export default AppBarStyled;
