import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';
import { useDispatch, useSelector } from 'react-redux';
import darkScrollbar from '@mui/material/darkScrollbar';
import useUserProfileSettings from 'hooks/useUserProfileSettings';
import { setTheme } from 'store/reducers/menu';
import { THEME_DARK } from 'store/reducers/menu';
// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }) {
    const currentTheme = useSelector((state) => state.menu.theme);
    const dispatch = useDispatch();
    const { data } = useUserProfileSettings();
    const theme = Palette(currentTheme);

    const themeTypography = Typography(`'Public Sans', sans-serif`);
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);
    const themeOptions = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    lg2: 1440,
                    xl: 1536
                }
            },
            direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: theme.palette,
            components: {
                MuiCssBaseline: {
                    styleOverrides: (themeParam) => ({
                        body: {
                            ...(themeParam.palette.mode === THEME_DARK ? darkScrollbar() : {}),
                            colorScheme: themeParam.palette.mode
                        }
                    })
                }
            },
            customShadows: themeCustomShadows,
            colorSchemes: {
                light: { shadowMain: '0px 2px 8px rgba(250, 250, 250, 0.15)' },
                dark: { shadowMain: '0px 2px 8px red' }
            },
            typography: themeTypography
        }),
        [theme, themeTypography, themeCustomShadows]
    );

    const themes = createTheme(themeOptions);
    themes.components = { ...themes.components, ...componentsOverride(themes) };

    useEffect(() => {
        if (data?.theme && currentTheme !== data.theme) {
            dispatch(setTheme(data.theme));
        }
    }, [data]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />

                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

ThemeCustomization.propTypes = {
    children: PropTypes.node
};
