import TextField from '@mui/material/TextField';
import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

const StringFilter = (props) => {
    const { item, applyValue, selectedOperator } = props;
    const [inputVal, setInputVal] = useState('');
    const debouncedApplyValue = useMemo(() => debounce(applyValue, 1000), [applyValue]);

    useEffect(() => {
        if (item.value) {
            setInputVal(item.value);
        } else {
            setInputVal('');
        }
    }, [item.value]);

    useEffect(() => {
        return () => {
            debouncedApplyValue.cancel();
        };
    }, [debouncedApplyValue]);

    const handleFilterChange = (e) => {
        setInputVal(e.target.value);
        debouncedApplyValue({ ...item, value: e.target.value });
    };

    const handleKeyDown = (e) => {
        e.stopPropagation();
        if (e.key === 'Backspace' && e.repeat) {
            debouncedApplyValue.flush();
        }
    };
    const label = selectedOperator?.label || item?.operator || 'All';
    return (
        <>
            <TextField
                sx={{
                    'input::placeholder, label': {
                        textTransform: 'capitalize',
                        top: '3px'
                    },
                    '.MuiInputBase-root.MuiInput-root': {
                        pt: '3px'
                    }
                }}
                size={'small'}
                value={inputVal}
                onChange={handleFilterChange}
                placeholder={label}
                variant="standard"
                autoComplete="off"
                onKeyDown={handleKeyDown}
                label={label}
                fullWidth
            />
        </>
    );
};

export default StringFilter;
