import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/reducers/menu';
import ModalTrackerComplete from 'components/ModalTrackerComplete/index';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './style.css';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG2 = useMediaQuery(theme.breakpoints.down('lg2'));
    const dispatch = useDispatch();
    const location = useLocation();

    const { drawerOpen } = useSelector((state) => state.menu);
    const isOpenModal = useSelector((state) => state.tracker.isOpenModal);

    // drawer toggler
    const handleDrawerToggle = () => {
        dispatch(openDrawer({ drawerOpen: !drawerOpen }));
    };

    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <Header open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={drawerOpen} handleDrawerToggle={handleDrawerToggle} />
            <Box component="main" sx={{ width: '100%', overflowX: 'auto', height: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                <Toolbar />
                <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />
                <TransitionGroup component={null}>
                    <Box sx={{ height: '100%', width: '100%' }}>
                        <CSSTransition key={location.pathname} classNames="page" timeout={1000}>
                            <Outlet />
                        </CSSTransition>
                    </Box>
                </TransitionGroup>
            </Box>
            {isOpenModal ? <ModalTrackerComplete /> : null}
        </Box>
    );
};

export default MainLayout;
