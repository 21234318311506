export const isEmpty = (val) => val === null || !(Object.keys(val) || val).length;
export const editableFields = ['Name', 'Task', 'Quantity', 'Comment', 'Status', 'Type'];

export const createElementMap = (arr) => {
    return new Map(arr.map((el) => [el.Id, { ...el, hierarchy: [] }]));
};

export const createChildrenMap = (arr) => {
    const childrenMap = new Map();

    arr.forEach((el) => {
        if (el.ParentId) {
            if (!childrenMap.has(el.ParentId)) {
                childrenMap.set(el.ParentId, []);
            }
            childrenMap.get(el.ParentId).push(el.Id);
        }
    });

    return childrenMap;
};

export const buildElementHierarchy = (elementId, elementMap, childrenMap, parentHierarchy = []) => {
    const element = elementMap.get(elementId);
    if (!element) return;

    element.hierarchy = [...parentHierarchy, element.Name];

    const children = childrenMap.get(elementId) || [];
    children.forEach((childId) => {
        buildElementHierarchy(childId, elementMap, childrenMap, element.hierarchy);
    });
};

export const calculateSortOrder = (prevSortOrder, nextSortOrder = null) => {
    if (nextSortOrder === null) {
        if (prevSortOrder <= 1) {
            return (prevSortOrder / 2 + 0.1);
        }
        return prevSortOrder / 2;
    }

    return (prevSortOrder + nextSortOrder) / 2;
};

export const updateSortOrders = (rows, targetParentId, initialSortOrder, valToUpdate, SORT_ORDER_STEP) => {
    let countSortOrder = initialSortOrder;
    return rows.map((el) => {
        if (el.ParentId !== targetParentId) return el;
        valToUpdate.current.set(el.Id, {
            Id: el.Id,
            SortOrder: countSortOrder,
            etag: el['@odata.etag']
        });
        const resEl = {
            ...el,
            SortOrder: countSortOrder
        };
        countSortOrder += SORT_ORDER_STEP;
        return resEl;
    });
};
