import { api } from './api';
import { operatorsString } from 'components/TableStringFilter/index';
import {
    OPERATORS_TO_FILTER,
    generateFilter,
    newFilterGenerate,
    operatorsFromMuiToOdata,
    stringOperators
} from 'helpers/changeFilterValueToFilterRequest';
import { toast } from 'react-toastify';
import { parseBatchRespoonse } from './batchRequest';
import { createBatchRequest } from './createBatchRequest';
import { generateCall } from 'helpers/batchGenerate';

const NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO = {
    Organization: 'Project/Organization/Id',
    AlternativeName: 'Project/AlternativeName',
    Project: 'Project/Id',
    State: 'State/Id'
};

const NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING = {
    Organization: 'Project/Organization/Name',
    Project: 'Project/AlternativeName',
    State: 'State/Name'
};

const NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO = {
    Project: 'Project/Name',
    Organization: 'Project/Organization/Name',
    AlternativeName: 'Project/AlternativeName',
    State: 'State/Name'
};

export const taskApi = api.injectEndpoints({
    tagTypes: ['Items'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getTask: builder.query({
            query: (args) => {
                let defaultFilter = generateFilter([
                    { name: 'Obsolete', operator: 'eq', value: false },
                    { name: 'Project/Status', operator: 'neq', value: 'Closed' }
                ]);

                let filter = defaultFilter;

                let sort = '';

                if (args?.filterValue?.items?.some((item) => !!item.value)) {
                    let filterValue = args.filterValue?.items?.map((item) => {
                        let value = Array.isArray(item.value)
                            ? item.value.map((el) => el[NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field] ? 'id' : 'label'])
                            : item.value;

                        if (value?.id) {
                            value = value.id;
                        }

                        if (Array.isArray(item.value) && item.value.length === 1) {
                            value = value.at(0);
                            if (value?.label) {
                                value = value[NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field] ? 'id' : 'label'];
                            }
                        }

                        let field = NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field]
                            ? NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field]
                            : item.field;

                        if (
                            Object.keys(stringOperators).includes(item.operator) &&
                            NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING[item.field]
                        ) {
                            field = NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING[item.field];
                        }

                        return { ...item, field: field, value: value };
                    });

                    let filterRes = newFilterGenerate({
                        filterValue: filterValue,
                        logicOperator: args.filterValue.logicOperator,
                        columns: args?.columns
                    });
                    if (filterRes) {
                        filter += ` and ${filterRes}`;
                    }
                }

                if (args?.sortInfo && Object.keys(args.sortInfo).length > 0) {
                    sort = `${
                        NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO[args.sortInfo.field]
                            ? NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO[args.sortInfo.field]
                            : args.sortInfo.field
                    } ${args.sortInfo.sort}`;
                }

                return {
                    url: `WorkItems?$expand=State,Project,Project($expand=Organization)&$select=Consultant,Developer,Name,CreatedAt,CreatedBy,ModifiedAt,ModifiedBy,Obsolete,ExternalId,Id,Priority,AssignedTo,Status,Url,NormalHoursEstimate,DevelopmentHours,MaximumHoursEstimate&$top=${
                        args?.limit
                    }&$skip=${args?.skip}&$count${filter ? '&$filter=' + filter.trim() : ''}${sort ? `&$orderby=${sort}` : ''}`
                    /* params: { '$skip': args?.skip ? args?.skip : 0, '$top': args?.limit ? args?.limit : 0 } */
                };
            },
            providesTags: [{ type: 'Items' }]
        }),

        getTaskCount: builder.query({
            query: () => ({
                url: 'WorkItems',
                params: { $count: true, $top: 1 }
            })
        }),

        getTaskOne: builder.query({
            query: (id) => {
                return {
                    url: `WorkItems(${id})`
                };
            }
        }),

        getTaskWithCount: builder.query({
            query: (args) => {
                let defaultFilter = generateFilter([
                    { name: 'Obsolete', operator: 'eq', value: false },
                    { name: 'Project/Status', operator: 'neq', value: 'Closed' }
                ]);

                let filter = defaultFilter;

                let sort = '';

                if (args?.filterValue?.items?.some((item) => !!item.value)) {
                    let filterValue = args.filterValue?.items?.map((item) => {
                        let value = Array.isArray(item.value)
                            ? item.value.map((el) => el[NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field] ? 'id' : 'label'])
                            : item.value;

                        if (value?.id) {
                            value = value.id;
                        }

                        if (Array.isArray(item.value) && item.value.length === 1) {
                            value = value.at(0);
                            if (value?.label) {
                                value = value[NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field] ? 'id' : 'label'];
                            }
                        }

                        let field = NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field]
                            ? NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field]
                            : item.field;

                        if (
                            Object.keys(stringOperators).includes(item.operator) &&
                            NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING[item.field]
                        ) {
                            field = NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING[item.field];
                        }

                        return { ...item, field: field, value: value };
                    });

                    let filterRes = newFilterGenerate({
                        filterValue: filterValue,
                        logicOperator: args.filterValue.logicOperator,
                        columns: args?.columns
                    });
                    if (filterRes) {
                        filter += ` and ${filterRes}`;
                    }
                }

                if (args?.sortInfo && Object.keys(args.sortInfo).length > 0) {
                    sort = `${
                        NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO[args.sortInfo.field]
                            ? NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO[args.sortInfo.field]
                            : args.sortInfo.field
                    } ${args.sortInfo.sort}`;
                }

                const baseUrl = 'WorkItems';
                const expandParams = '$expand=State,Project,Project($expand=Organization)';
                const selectParams =
                    '$select=Consultant,Developer,Name,CreatedAt,CreatedBy,ModifiedAt,ModifiedBy,Obsolete,ExternalId,Id,Priority,AssignedTo,Status,Url,NormalHoursEstimate,DevelopmentHours,MaximumHoursEstimate';

                const taskUrl = `${baseUrl}?${expandParams}&${selectParams}&$top=${args?.limit}&$skip=${args?.skip}${
                    filter ? '&$filter=' + encodeURIComponent(filter.trim()) : ''
                }${sort ? '&$orderby=' + encodeURIComponent(sort) : ''}`;

                const countUrl = `${baseUrl}?$count=true&${expandParams}${
                    filter ? '&$filter=' + encodeURIComponent(filter.trim()) : ''
                }&$top=1`;

                const calls = [
                    generateCall({
                        url: taskUrl,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    }),
                    generateCall({
                        url: countUrl,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    })
                ];

                const [batchRequest, boundary] = createBatchRequest([calls]);

                return {
                    url: `$batch`,
                    method: 'POST',
                    body: batchRequest,
                    headers: {
                        Accept: '*',
                        'Content-Type': `multipart/mixed; boundary=${boundary}`
                    },
                    responseHandler: async (response) => {
                        if (!response?.body) throw new Error('Invalid response body');
                        const reader = response.body.getReader();
                        let fullText = '';
                        let done = false;

                        while (!done) {
                            const { value, done: readerDone } = await reader.read();
                            if (value) {
                                fullText += new TextDecoder().decode(value);
                            }
                            done = readerDone;
                        }

                        const { errors, objects } = parseBatchRespoonse(fullText);

                        if (errors?.length) {
                            errors.forEach((err) =>
                                toast.error(`MESSAGE: ${err?.error?.message || 'Unknown error'} CODE: ${err?.error?.code || 'N/A'}`)
                            );
                            return { status: false, errors };
                        }

                        return { status: true, value: objects };
                    }
                };
            },

            transformResponse: async (response) => {
                try {
                    const data = (await response?.value) || [];
                    if (data.length < 2) {
                        throw new Error('Incomplete data received from batch response');
                    }

                    const taskResponse = data[0]?.value;
                    const countResponse = data[1]?.['@odata.count'];

                    let value = [];
                    let count = 0;

                    if (taskResponse && typeof taskResponse === 'string') {
                        value = JSON.parse(taskResponse)?.value || [];
                    } else if (Array.isArray(taskResponse)) {
                        value = taskResponse;
                    }

                    count = countResponse || 0;

                    return { value, count };
                } catch (error) {
                    console.error('Error in transformResponse:', error);
                    toast.error('Data transformation failed');
                    throw error;
                }
            },

            providesTags: [{ type: 'Items' }]
        }),

        getTaskWithProjectOne: builder.query({
            query: (id) => {
                return {
                    url: `WorkItems(${id})?$expand=Project`
                };
            }
        }),

        getGlobalParams: builder.query({
            query: () => {
                return {
                    url: `GlobalParams`
                };
            }
        }),

        getTaskDetail: builder.query({
            query: (id) => {
                return {
                    url: `WorkItems(${id})`,
                    params: { $expand: 'Project($select=Name;$expand=Organization($select=Name)),Estimates,State' }
                };
            }
        }),

        getTaskAssignedToMe: builder.query({
            query: (args) => {
                let defaultFilter = generateFilter([
                    { name: 'Obsolete', operator: 'eq', value: false },
                    { name: 'Project/Status', operator: 'neq', value: 'Closed' }
                ]);
                if (args?.accauntsList && args.accauntsList.length > 0) {
                    defaultFilter += ` and ${generateFilter([{ name: 'AssignedTo', operator: 'inlist', value: args.accauntsList }])}`;
                }

                let filter = '';
                let sort = '';
                let params = {};

                // if (args?.accauntsList && args.accauntsList.length > 0) {
                //     defaultFilter += ' and ';
                //     if (args.accauntsList.length > 1) {
                //         defaultFilter += `tolower(AssignedTo) in (${args.accauntsList.map(el => `'${el.toLowerCase()}'`).join(',')})`;
                //     } else {
                //         defaultFilter += `tolower(AssignedTo) eq '${args.accauntsList.map(el => el.toLowerCase()).join(',')}'`;
                //     }
                // }

                if (args?.filterValue?.items?.some((item) => !!item.value)) {
                    let filterValue = args.filterValue?.items?.map((item) => {
                        let value = Array.isArray(item.value)
                            ? item.value.map((el) => el[NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field] ? 'id' : 'label'])
                            : item.value;

                        if (value?.id) {
                            value = value.id;
                        }

                        if (Array.isArray(item.value) && item.value.length === 1) {
                            value = value.at(0);
                            if (value?.label) {
                                value = value[NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field] ? 'id' : 'label'];
                            }
                        }

                        let field = NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field]
                            ? NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field]
                            : item.field;

                        if (
                            Object.keys(stringOperators).includes(item.operator) &&
                            NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING[item.field]
                        ) {
                            field = NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING[item.field];
                        }

                        return { ...item, field: field, value: value };
                    });

                    let filterRes = newFilterGenerate({
                        filterValue: filterValue,
                        logicOperator: args.filterValue.logicOperator,
                        columns: args?.columns
                    });
                    if (filterRes) {
                        filter = filterRes;
                    }
                }

                if (filter && defaultFilter) {
                    defaultFilter += ' and ';
                }

                filter = defaultFilter + filter;
                params = {
                    $skip: args?.skip ? args?.skip : 0,
                    $top: args?.limit ? args?.limit : 0,
                    $filter: filter.length > 0 ? filter.trim() : ''
                };

                if (args?.sortInfo && Object.keys(args.sortInfo).length > 0) {
                    sort = `${
                        NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO[args.sortInfo.field]
                            ? NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO[args.sortInfo.field]
                            : args.sortInfo.field
                    } ${args.sortInfo.sort}`;
                    params['$orderby'] = sort;
                }

                return {
                    url: `WorkItems?$expand=State,Project,Project($expand=Organization)&$select=Consultant,Developer,CreatedAt,CreatedBy,ModifiedAt,ModifiedBy,Name,Obsolete,ExternalId,Id,Priority,AssignedTo,Status,Url,NormalHoursEstimate,DevelopmentHours,MaximumHoursEstimate`,
                    params
                };
            },
            providesTags: [{ type: 'Items' }]
        }),

        getTaskAssignedToMeWithCount: builder.query({
            query: (args) => {
                let defaultFilter = generateFilter([
                    { name: 'Obsolete', operator: 'eq', value: false },
                    { name: 'Project/Status', operator: 'neq', value: 'Closed' }
                ]);

                if (args?.accauntsList && args.accauntsList.length > 0) {
                    defaultFilter += ` and ${generateFilter([{ name: 'AssignedTo', operator: 'inlist', value: args.accauntsList }])}`;
                }

                let filter = '';
                let sort = '';
                let params = {};

                if (args?.filterValue?.items?.some((item) => !!item.value)) {
                    let filterValue = args.filterValue?.items?.map((item) => {
                        let value = Array.isArray(item.value)
                            ? item.value.map((el) => el[NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field] ? 'id' : 'label'])
                            : item.value;

                        if (value?.id) {
                            value = value.id;
                        }

                        if (Array.isArray(item.value) && item.value.length === 1) {
                            value = value.at(0);
                            if (value?.label) {
                                value = value[NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field] ? 'id' : 'label'];
                            }
                        }

                        let field = NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field]
                            ? NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO[item.field]
                            : item.field;

                        if (
                            Object.keys(stringOperators).includes(item.operator) &&
                            NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING[item.field]
                        ) {
                            field = NAMES_FOR_FILTER_TABLE_AND_ASSIGED_TO_STRING[item.field];
                        }

                        return { ...item, field: field, value: value };
                    });

                    let filterRes = newFilterGenerate({
                        filterValue: filterValue,
                        logicOperator: args.filterValue.logicOperator,
                        columns: args?.columns
                    });

                    if (filterRes) {
                        filter = filterRes;
                    }
                }

                if (filter && defaultFilter) {
                    defaultFilter += ' and ';
                }

                filter = defaultFilter + filter;
                params = {
                    $skip: args?.skip ? args?.skip : 0,
                    $top: args?.limit ? args?.limit : 0,
                    $filter: filter.length > 0 ? filter.trim() : ''
                };

                if (args?.sortInfo && Object.keys(args.sortInfo).length > 0) {
                    const sortField = NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO[args.sortInfo.field]
                        ? NAMES_FOR_SORT_TABLE_AND_ASSIGED_TO[args.sortInfo.field]
                        : args.sortInfo.field;
                    sort = `${sortField} ${args.sortInfo.sort}`;
                    params['$orderby'] = sort;
                }

                const taskUrl = `WorkItems?$expand=State,Project,Project($expand=Organization)&$select=Consultant,Developer,CreatedAt,CreatedBy,ModifiedAt,ModifiedBy,Name,Obsolete,ExternalId,Id,Priority,AssignedTo,Status,Url,NormalHoursEstimate,DevelopmentHours,MaximumHoursEstimate&${new URLSearchParams(
                    params
                ).toString()}`;

                const countUrl = `WorkItems?$count=true&$top=1&${new URLSearchParams(params).toString()}`;

                const calls = [
                    generateCall({
                        url: taskUrl,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    }),
                    generateCall({
                        url: countUrl,
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' }
                    })
                ];
                const [batchRequest, boundary] = createBatchRequest([calls]);

                return {
                    url: `$batch`,
                    method: 'POST',
                    body: batchRequest,
                    headers: {
                        Accept: '*',
                        'Content-Type': `multipart/mixed; boundary=${boundary}`
                    },
                    responseHandler: async (response) => {
                        if (!response?.body) throw new Error('Invalid response body');
                        const reader = response.body.getReader();
                        let fullText = '';
                        let done = false;

                        while (!done) {
                            const { value, done: readerDone } = await reader.read();
                            if (value) {
                                fullText += new TextDecoder().decode(value);
                            }
                            done = readerDone;
                        }

                        const { errors, objects } = parseBatchRespoonse(fullText);

                        if (errors?.length) {
                            errors.forEach((err) =>
                                toast.error(`MESSAGE: ${err?.error?.message || 'Unknown error'} CODE: ${err?.error?.code || 'N/A'}`)
                            );
                            return { status: false, errors };
                        }

                        return { status: true, value: objects };
                    }
                };
            },

            transformResponse: async (response) => {
                try {
                    const data = (await response?.value) || [];
                    if (data.length < 2) {
                        throw new Error('Incomplete data received from batch response');
                    }

                    const taskResponse = data[0]?.value;
                    const countResponse = data[1]?.['@odata.count'];

                    let value = [];
                    let count = 0;

                    if (taskResponse && typeof taskResponse === 'string') {
                        value = JSON.parse(taskResponse)?.value || [];
                    } else if (Array.isArray(taskResponse)) {
                        value = taskResponse;
                    }

                    count = countResponse || 0;

                    return { value, count };
                } catch (error) {
                    console.error('Error in transformResponse:', error);
                    toast.error('Data transformation failed');
                    throw error;
                }
            },

            providesTags: [{ type: 'Items' }]
        }),

        getFilterOptions: builder.query({
            query: ({ entity, top, skip, text, fieldName, orgsToFilter }) => {
                let expand = '';
                let filter = '';
                let apply = '';
                const isAssingedTo = fieldName === 'AssignedTo';

                if (isAssingedTo) {
                    apply = text
                        ? `WorkItems?$apply=${encodeURIComponent(
                              `filter(contains(tolower(AssignedTo),'${text.toLowerCase()}'))/groupby((AssignedTo))`
                          )}&$top=${top}&$count=true`
                        : `WorkItems?$apply=${encodeURIComponent(
                              `filter(AssignedTo ne '')/groupby((AssignedTo))`
                          )}&$top=${top}&$count=true`;
                } else {
                    filter = text ? `contains(tolower(${fieldName ? fieldName : 'Name'}),'${text.toLowerCase()}')` : '';
                    if (orgsToFilter && orgsToFilter.length > 0) {
                        expand = 'Organization($select=Name)';
                        if (text) {
                            filter += ' and ';
                        }
                        filter += '(';
                        orgsToFilter.forEach((org, index) => {
                            filter += `tolower(Organization/Name) eq '${org.toLowerCase()}'`;
                            if (orgsToFilter.length - 1 > index) {
                                filter += ' and ';
                            }
                        });
                        filter += ')';
                    }
                }

                const encodeFilter = encodeURIComponent(filter.trim());

                return {
                    url: apply
                        ? apply
                        : `${entity}?$select=${fieldName},Id${expand ? '&$expand=' + expand : ''}${
                              filter ? `&$filter=${encodeFilter}` : ''
                          }`,
                    params: { $top: top, $skip: skip, $count: true, $orderby: `${fieldName} asc` }
                };
            },
            transformResponse: (response, _, args) => {
                const res = [{ label: '', id: null }];
                response.value.forEach((item) => {
                    if (item[args.fieldName] && !res.some((el) => el.label === item[args.fieldName])) {
                        res.push({ label: item[args.fieldName], id: item.Id });
                    }
                });
                return { value: res, count: response['@odata.count'] };
            },
            providesTags: [{ type: 'FilterList' }]
        }),

        getAccountsById: builder.query({
            query: (userId) => {
                return {
                    url: `UserAccounts`,
                    params: { $filter: `UserId eq ${userId}` }
                };
            },

            transformResponse: (response) => {
                return response.value.map((item) => item.Account);
            }
        }),

        getState: builder.query({
            query: () => {
                return {
                    url: `WorkItemState`,
                    params: { $select: 'Name,Id', $skip: 0 }
                };
            },
            transformResponse: (response) => {
                if (response?.value.length === 0) return response;
                let uniqueValues = [];
                response.value.forEach((val) => {
                    if (val?.Name && typeof val?.Name === 'string' && uniqueValues.findIndex((i) => i.name === val.Name) === -1) {
                        uniqueValues.push({ name: val.Name, stateId: val.Id });
                    }
                });
                return uniqueValues.map((item) => {
                    return { label: item.name, id: item.name, StateId: item.stateId };
                });
            },
            providesTags: [{ type: 'FilterList' }]
        }),

        getUserWorkItems: builder.query({
            query: (workItemId) => {
                return {
                    url: `UserWorkItems`,
                    params: { $expand: 'User,WorkItem', $skip: 0, $filter: `WorkItem/Id eq ${workItemId} and User/Active eq true` }
                };
            },
            providesTags: [{ type: 'UserWorkItems' }]
        }),

        addUserWorkItem: builder.mutation({
            query: (body) => {
                return {
                    url: `UserWorkItems`,
                    method: 'POST',
                    body
                };
            },
            invalidatesTags: [{ type: 'Items' }, { type: 'UserWorkItems' }]
        }),

        deleteUserWorkItem: builder.mutation({
            query: (id) => {
                return {
                    url: `UserWorkItems(${id})`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: [{ type: 'Items' }, { type: 'UserWorkItems' }]
        }),

        getSearchUsers: builder.query({
            query: ({ search, limit }) => {
                const filter = [`Active eq true`];

                if (search) {
                    filter.push(`contains(tolower(FullName),'${search?.toString()?.toLowerCase()}')`);
                }

                return {
                    url: `Users`,
                    params: { $filter: filter.join(' and '), $top: limit || 20, $skip: 0, $orderby: 'FullName ASC' }
                };
            }
        }),

        getFilterTableOptions: builder.query({
            query: ({ entity, limit = 20, skip = 0, filter = null, sortInfo, expandInfo = null }) => {
                let params = { $top: limit, $skip: skip };
                if (filter) {
                    let res = [];
                    if (filter?.some((item) => !!item.value)) {
                        filter
                            .filter((item) => !!item.value)
                            .forEach((item) => {
                                let entity = null;
                                let value = null;
                                const isStringOperator = operatorsString.findIndex((el) => el.operator === item.operator) !== -1;
                                if (expandInfo && expandInfo.find((el) => el.expand === item.name)) {
                                    let info = expandInfo.find((el) => el.expand === item.name);
                                    if (!info) return;
                                    entity = `${info.expand}/${info.name}`;
                                } else {
                                    entity = item.name;
                                }
                                value = isStringOperator ? `'${item.value.toLowerCase()}'` : item.value;

                                if (Array.isArray(value)) {
                                    if (value.length > 1) {
                                        value = value.map((val) => (typeof val === 'number' ? val : `'${val.toLowerCase()}'`));
                                    } else {
                                        value = typeof value[0] === 'number' ? value[0] : `'${value[0].toLowerCase()}'`;
                                    }
                                }
                                if (Array.isArray(value) && value.length > 1) {
                                    if (item.operator === 'notinlist') {
                                        res.push(`not (trim(tolower(${entity})) in (${value.join(',')}))`);
                                    } else {
                                        res.push(`trim(tolower(${entity})) in (${value.join(',')})`);
                                    }
                                } else {
                                    res.push(
                                        `${OPERATORS_TO_FILTER[item.operator]({
                                            entity: typeof value === 'number' ? `${entity}` : `trim(tolower(${entity}))`,
                                            value: value
                                        })}`
                                    );
                                }
                            });
                    }
                    if (res?.length) {
                        params['$filter'] = `${res.join(' and ')}`;
                    }
                }

                if (expandInfo) {
                    let res = [];
                    expandInfo.forEach((el) => {
                        res.push(`${el.expand}($select=Id,${el.name})`);
                    });
                    params['$expand'] = res.join(',');
                }

                if (sortInfo && Object.keys(sortInfo).length > 0) {
                    let expandInfoElem = expandInfo?.find((el) => el.expand === sortInfo.name);
                    let name = expandInfoElem?.expand ? `${expandInfoElem.expand}/${expandInfoElem.name}` : sortInfo.name;
                    params['$orderby'] = `${name} ${sortInfo.dir === 1 ? 'ASC' : 'DESC'}`;
                }

                params['$count'] = true;
                return {
                    url: `${entity}`,
                    params
                };
            }
        }),

        new_getFilterTableOptions: builder.query({
            query: ({ entity, limit = 20, skip = 0, expandInfo = null, ...args }) => {
                let params = { $top: limit, $skip: skip };

                if (args?.filterValue?.items?.some((item) => !!item.value)) {
                    let filterValue = args.filterValue?.items?.map((item) => {
                        let expandEntity = expandInfo?.find((el) => el?.expand === item?.field);
                        let field = item.field;
                        if (expandEntity) {
                            field = `${expandEntity?.expand}/${expandEntity.name}`;
                        }
                        let value = item.value;
                        if (Array.isArray(item.value) && item.value.length === 1) {
                            value = item.value.at(0);
                        }
                        return { ...item, field: field, value: value };
                    });

                    let filterRes = newFilterGenerate({
                        filterValue: filterValue,
                        logicOperator: args.filterValue.logicOperator,
                        columns: args?.columns
                    });

                    if (filterRes) {
                        params['$filter'] = filterRes;
                    }
                }

                if (args?.sortInfo && Object.keys(args.sortInfo).length > 0) {
                    let sort = '';
                    let expandEntity = expandInfo?.find((el) => el?.expand === args.sortInfo.field);

                    if (expandEntity) {
                        sort = `${expandEntity.expand}/${expandEntity.name} ${args.sortInfo.sort}`;
                    } else {
                        sort = `${args.sortInfo.field} ${args.sortInfo.sort}`;
                    }
                    params['$orderby'] = sort;
                }

                if (expandInfo) {
                    let res = [];
                    expandInfo.forEach((el) => {
                        res.push(`${el.expand}($select=Id,${el.name})`);
                    });
                    params['$expand'] = res.join(',');
                }

                params['$count'] = true;
                return {
                    url: `${entity}`,
                    params
                };
            }
        }),

        getWorkItemOptions: builder.query({
            query: ({ limit = 20, skip = 0, value = null, filterValue = null, selectedWorktemId = null, sortInfo, columnsWithTypes }) => {
                let params = {
                    $top: limit,
                    $skip: skip,
                    $expand: 'Project($select=Name,AlternativeName;$expand=Organization($select=Name))',
                    $select: 'Id,Name,ExternalId',
                    $orderby: 'ExternalId ASC'
                };

                if (selectedWorktemId) {
                    params['$filter'] = `Id eq ${selectedWorktemId}`;
                } else {
                    if (value) {
                        if (!isNaN(+value)) {
                            params['$filter'] = `ExternalId eq ${+value}`;
                        } else {
                            params['$filter'] = `contains(tolower(trim(Name)),'${value.toLowerCase().trim()}')`;
                        }
                    }

                    if (filterValue && filterValue.items.some((el) => !!el.value || el?.length)) {
                        let filters = [];
                        filterValue.items.forEach((el) => {
                            if (!!el.value || el?.length) {
                                filters.push(
                                    `${OPERATORS_TO_FILTER[operatorsFromMuiToOdata[el.operator]]({
                                        entity:
                                            el.field === 'Project' || el.field === 'Organization'
                                                ? el.field === 'Project'
                                                    ? 'tolower(trim(Project/AlternativeName))'
                                                    : 'tolower(trim(Project/Organization/Name))'
                                                : columnsWithTypes[el.field] === 'number'
                                                ? el.field
                                                : `tolower(trim(${el.field}))`,
                                        value: columnsWithTypes[el.field] === 'number' ? +el.value : `'${el.value.toLowerCase()}'`
                                    })}`
                                );
                            }
                        });

                        let filter = filters.join(' and ');

                        if (params['$filter']) {
                            params['$filter'] += ` and ${filter}`;
                        } else {
                            params['$filter'] = filter;
                        }
                    }
                }

                if (sortInfo && Object.keys(sortInfo).length > 0) {
                    let sort = `${
                        sortInfo.field === 'Project'
                            ? 'Project/AlternativeName'
                            : sortInfo.field === 'Organization'
                            ? 'Project/Organization/Name'
                            : sortInfo.field
                    } ${sortInfo.sort}`;
                    params['$orderby'] = sort;
                }

                if (params['$filter'])
                    params['$filter'] += ` and ${generateFilter([
                        { name: 'Obsolete', operator: 'eq', value: false },
                        { name: 'Project/Status', operator: 'neq', value: 'Closed' }
                    ])}`;
                else
                    params['$filter'] = `${generateFilter([
                        { name: 'Obsolete', operator: 'eq', value: false },
                        { name: 'Project/Status', operator: 'neq', value: 'Closed' }
                    ])}`;
                return {
                    url: `WorkItems`,
                    params
                };
            }
        }),

        getProjectItemOptions: builder.query({
            query: ({
                limit = 20,
                skip = 0,
                value = null,
                defaultFilter,
                filterValue = null,
                selectedItemId = null,
                sortInfo,
                columnsType
            }) => {
                let params = {
                    $top: limit,
                    $skip: skip,
                    $expand: 'Organization($select=Name)',
                    $select: 'Id,Name,AlternativeName',
                    $orderby: 'Id ASC',
                    $filter: ''
                };

                if (selectedItemId) {
                    params['$filter'] = `Id eq ${selectedItemId}`;
                } else {
                    if (value) {
                        if (!isNaN(+value)) {
                            params['$filter'] = `Id eq ${+value}`;
                        } else {
                            params['$filter'] = `contains(tolower(trim(AlternativeName)),'${value.toLowerCase().trim()}')`;
                        }
                    }

                    if (filterValue && filterValue.items.some((el) => !!el.value || el?.length)) {
                        let filters = [];
                        filterValue.items.forEach((el) => {
                            if (!!el.value || el?.length) {
                                filters.push(
                                    `${OPERATORS_TO_FILTER[operatorsFromMuiToOdata[el.operator]]({
                                        entity:
                                            el.field === 'Organization'
                                                ? 'tolower(trim(Organization/Name))'
                                                : columnsType[el.field] !== 'string'
                                                ? el.field
                                                : `tolower(trim(${el.field}))`,
                                        value: columnsType[el.field] !== 'string' ? +el.value : `'${el.value.toLowerCase()}'`
                                    })}`
                                );
                            }
                        });

                        let filter = filters.join(' and ');

                        if (params['$filter']) {
                            params['$filter'] += ` and ${filter}`;
                        } else {
                            params['$filter'] = filter;
                        }
                    }
                }

                if (defaultFilter) {
                    params['$filter'] += `${params['$filter'] ? ` and ` : ''}${defaultFilter}`;
                }

                if (sortInfo && Object.keys(sortInfo).length > 0) {
                    let sort = `${sortInfo.field === 'Organization' ? 'Organization/Name' : sortInfo.field} ${sortInfo.sort}`;
                    params['$orderby'] = sort;
                }

                if (params['$filter'])
                    params['$filter'] += ` and ${generateFilter([{ name: 'Status', operator: 'neq', value: 'Closed' }])}`;
                else params['$filter'] = `${generateFilter([{ name: 'Status', operator: 'neq', value: 'Closed' }])}`;

                if (!params['$filter']) {
                    delete params.$filter;
                }
                return {
                    url: `Projects`,
                    params
                };
            }
        }),

        updateTableEntity: builder.mutation({
            query: ({ entity, id, data, etag }) => {
                etag = etag.replaceAll('\\', '');
                return {
                    url: `${entity} (${id})`,
                    method: 'PATCH',
                    body: data,
                    headers: { 'If-Match': etag }
                };
            },
            invalidatesTags: [{ type: 'Items' }, { type: 'FilterList' }]
        }),

        updateWorkItemEstimate: builder.mutation({
            query: ({ entity, id, data, etag }) => {
                return {
                    url: `${entity}(${id})`,
                    method: 'PATCH',
                    body: data,
                    headers: { 'If-Match': etag }
                };
            }
        })
    })
});

export const {
    useGetTaskQuery,
    useGetWorkItemOptionsQuery,
    useGetProjectItemOptionsQuery,
    useGetTaskWithProjectOneQuery,
    useNew_getFilterTableOptionsQuery,
    useGetFilterTableOptionsQuery,
    useGetTaskDetailQuery,
    useGetGlobalParamsQuery,
    useGetFilterOptionsQuery,
    useGetTaskAssignedToMeQuery,
    useGetAccountsByIdQuery,
    useGetStateQuery,
    useUpdateTableEntityMutation,
    useGetUserWorkItemsQuery,
    useGetSearchUsersQuery,
    useGetTaskOneQuery,
    useDeleteUserWorkItemMutation,
    useAddUserWorkItemMutation,
    useGetTaskCountQuery,
    useGetTaskWithCountQuery,
    useGetTaskAssignedToMeWithCountQuery,
    useUpdateWorkItemEstimateMutation
} = taskApi;
